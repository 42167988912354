import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';

const CourseItem = ({
  icon,
  description,
  subtitle,
  subtitle2,
  subtitle3,
  title,
  whiteText,
}) => {
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="w-full max-w-xs m-3">
        <GatsbyImage image={icon} alt={title} />
      </div>
      <div className="w-full text-center">
        <h3
          className={`${
            whiteText ? 'text-white' : 'text-orange-500'
          } font-header leading-none text-4xl capitalize max-w-sm mx-auto lg:text-left`}
        >
          {title}
        </h3>
        <div
          className={`${
            whiteText ? 'text-white' : 'text-gray-500'
          } font-light text-base max-w-sm mx-auto lg:text-left`}
        >
          {subtitle}
        </div>
        <div
          className={`${
            whiteText ? 'text-white' : 'text-gray-500'
          } font-light text-base max-w-sm mx-auto lg:text-left`}
        >
          {subtitle2}
        </div>
        <div
          className={`${
            whiteText ? 'text-white' : 'text-gray-500'
          } font-light text-base max-w-sm mx-auto lg:text-left`}
        >
          {subtitle3}
        </div>
        <div
          className={`${
            whiteText ? 'text-white' : 'text-gray-500'
          } font-light text-base mt-3 max-w-sm mx-auto lg:text-left`}
        >
          {description}
        </div>
      </div>
    </div>
  );
};

CourseItem.propTypes = {
  icon: PropTypes.shape({ publicURL: PropTypes.string }).isRequired,
  description: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  subtitle2: PropTypes.string,
  subtitle3: PropTypes.string,
  title: PropTypes.string.isRequired,
  whiteText: PropTypes.bool,
};

CourseItem.defaultProps = {
  subtitle2: '',
  subtitle3: '',
  whiteText: false,
};

export default CourseItem;
