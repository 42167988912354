import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { FaCaretRight } from 'react-icons/fa';
import Layout from '../components/layout';
import SEO from '../components/seo';
import CourseItem from '../components/course-item';

const OurCoursesPage = () => {
  const data = useStaticQuery(graphql`query OurCoursesImages {
  pianoInfantil: file(relativePath: {eq: "piano-infantil.png"}) {
    childImageSharp {
      gatsbyImageData(width: 480, quality: 100, layout: CONSTRAINED)
    }
  }
  violin: file(relativePath: {eq: "violin.png"}) {
    childImageSharp {
      gatsbyImageData(width: 480, quality: 100, layout: CONSTRAINED)
    }
  }
  guitarra: file(relativePath: {eq: "guitarra.png"}) {
    childImageSharp {
      gatsbyImageData(width: 480, quality: 100, layout: CONSTRAINED)
    }
  }
  piano: file(relativePath: {eq: "piano.png"}) {
    childImageSharp {
      gatsbyImageData(width: 480, quality: 100, layout: CONSTRAINED)
    }
  }
  canto: file(relativePath: {eq: "canto.png"}) {
    childImageSharp {
      gatsbyImageData(width: 480, quality: 100, layout: CONSTRAINED)
    }
  }
  bateria: file(relativePath: {eq: "bateria.png"}) {
    childImageSharp {
      gatsbyImageData(width: 480, quality: 100, layout: CONSTRAINED)
    }
  }
}
`);
  return (
    <Layout>
      <SEO title="¿Conoces nuestras cursos?" />
      <section>
        <div className="container mx-auto">
          <h2 className="flex items-start justify-center font-header leading-none text-orange-500 text-3xl lg:max-w-lg lg:ml-0 lg:mr-auto">
            <FaCaretRight className="w-10 h-10" />
            <span className="lg:w-72">
              ¿Conoces nuestros <span className="uppercase">cursos</span>?
            </span>
          </h2>
        </div>
        <div>
          <ul className="container mx-auto flex flex-col lg:flex-row lg:flex-wrap lg:items-center">
            <li className="my-3 w-full lg:w-1/3 lg:my-0">
              <CourseItem
                icon={data.pianoInfantil.childImageSharp.gatsbyImageData}
                title="Curso infantil"
                subtitle="Para niños de 4 a 6 años"
                description="Curso para iniciar en la música, con el piano, el canto y dinámicas musicales. En clases, se desarrollará la capacidad de atención, la motricidad, el lenguaje y la disciplina de tu hijo, preparándolo para un excelente desempeño escolar."
              />
            </li>
            <li className="my-3 w-full lg:w-1/3 lg:my-0">
              <CourseItem
                icon={data.violin.childImageSharp.gatsbyImageData}
                title="Violín"
                subtitle="Violín Junior - 7 a 12 años"
                subtitle2="Violín Juvenil - 13 a 17 años"
                subtitle3="Violín Adultos - 18 años en adelante"
                description="El elegante violín causa mucha admiración. Tu hijo aprenderá a entonarlo y se lucirá en sus presentaciones. Además te llenará de satisfacción tener un violinista en la familia."
              />
            </li>
            <li className="my-3 w-full lg:w-1/3 lg:my-0">
              <CourseItem
                icon={data.guitarra.childImageSharp.gatsbyImageData}
                title="Guitarra"
                subtitle="Guitarra Junior - 7 a 12 años"
                subtitle2="Guitarra Juvenil - 13 a 17 años"
                subtitle3="Guitarra Adultos - 18 años en adelante"
                description="Si lo que a tu hijo le gusta es la música moderna como el pop o el rock, la guitarra es el instrumento ideal. Le daremos todas las herramientas para que se convierta en un gran guitarrista."
              />
            </li>
          </ul>
        </div>
        <div className="courses-orange-gradient pt-12 pb-24 md:pt-24 lg:pt-40 lg:pb-48 xl:pb-56 2xl:pt-56 5xl:pt-72">
          <ul className="container mx-auto flex flex-col lg:flex-row lg:flex-wrap lg:items-center">
            <li className="my-3 w-full lg:w-1/3 lg:my-0">
              <CourseItem
                icon={data.piano.childImageSharp.gatsbyImageData}
                title="Piano"
                subtitle="Piano Junior - 7 a 12 años"
                subtitle2="Piano Juvenil - 13 a 17 años"
                subtitle3="Piano Adultos - 18 años en adelante"
                description="Excelente para desarrollar el oído musical y la motricidad fina. Este es uno de nuestros programas más popular y es que el piano encanta por su sonido y elegancia."
                whiteText
              />
            </li>
            <li className="my-3 w-full lg:w-1/3 lg:my-0">
              <CourseItem
                icon={data.canto.childImageSharp.gatsbyImageData}
                title="Canto"
                subtitle="Canto Junior - 7 a 12 años"
                subtitle2="Canto Juvenil - 13 a 17 años"
                subtitle3="Canto Adultos - 18 años en adelante"
                description="Si tu hijo/a es por naturaleza extrovertido y sociable, el programa de canto es ideal. Este programa de canto desarrolla la personalidad y ayuda a que nuestros estudiantes se suelten en el escenario."
                whiteText
              />
            </li>
            <li className="my-3 w-full lg:w-1/3 lg:my-0">
              <CourseItem
                icon={data.bateria.childImageSharp.gatsbyImageData}
                title="Batería"
                subtitle="Batería Junior - 7 a 12 años"
                subtitle2="Batería Juvenil - 13 a 17 años"
                subtitle3="Batería Adultos - 18 años en adelante"
                description="Para niños y jóvenes dinámicos. Este instrumento desarrolla mucho el ritmo, la motricidad y ayuda a aumentar la autoestima y confianza."
                whiteText
              />
            </li>
          </ul>
        </div>
        <div className="relative z-20 -mt-24 pt-16 pb-6 px-6 text-yellow-500 courses-yellow-gradient-cloud-footer lg:-mt-40 lg:pt-32 lg:pb-24 xl:-mt-56"></div>
      </section>
    </Layout>
  );
};

export default OurCoursesPage;
